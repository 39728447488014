import { StepMenuItemMap } from '../../../core/models/app.models';

export const eventFormSteps: StepMenuItemMap = {
  eventInformation: {
    stepId: 'eventInformation',
    stepName: 'Event Infos',
    active: true,
    state: 'unvalidated',
  },
  genresTags: {
    stepId: 'genresTags',
    stepName: 'Genres & Tags',
    active: false,
    state: 'unvalidated',
  },
  ticketing: {
    stepId: 'ticketing',
    stepName: 'Ticketing',
    active: false,
    state: 'unvalidated',
  },
  booster: {
    stepId: 'booster',
    stepName: 'Booster',
    active: false,
    state: 'unvalidated',
  },
  overview: {
    stepId: 'overview',
    stepName: 'Übersicht',
    active: false,
    state: 'unvalidated',
  },
};
